import React from "react";
import clsx from "clsx";

import { SITE } from "@constants";
import { Carousel, Testimonial, Review } from "@molecules";
import { Container } from "@atoms";

const TestimonialCarousel = ({
  bgColor,
  testimonials,
  className: _className,
}) => {
  return (
    <section
      className={clsx(
        {
          "py-12": SITE === "robActon",
          "py-16 md:py-32": SITE === "default",
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      <Container variant="xl">
        <div className="px-4 md:px-8">
          <Carousel
            stretch
            maxVisible={SITE === "default" ? 1 : 3}
            showInactive
          >
            {testimonials?.map(t => {
              if (SITE === "robActon") {
                return (
                  <div key={t.uid} className="h-full px-2">
                    <Review hideStars {...t} />
                  </div>
                );
              }
              return <Testimonial key={t.uid} color={bgColor} {...t} />;
            })}
          </Carousel>
        </div>
      </Container>
    </section>
  );
};

TestimonialCarousel.defaultProps = {
  bgColor: "midnight",
};

export default TestimonialCarousel;
